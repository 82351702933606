import Logo from '../logo512.png';
function Header() {
  return (
    <header className='app-header'>
      <img src={Logo} alt='React logo' className='react-logo' />
      <h1>React Quiz App</h1>
    </header>
  );
}
export default Header;
