import { useEffect, useReducer } from "react";
import Header from "./Components/Header";
import Main from "./Components/Main";
import "./index.css";
import Loader from "./Components/Loader";
import Error from "./Components/Error";
import StartScreen from "./Components/StartScreen";
import Questions from "./Components/Questions";
import NextButton from "./Components/NextButton";
import Progress from "./Components/Progress";
import FinishQuiz from "./Components/FinishQuiz";
import Timer from "./Components/FooterComponent/Timer";
import Footer from "./Components/FooterComponent/Footer";
import data from './data/questions.json';

const SECONDS_PER_QUESTION = 30;

const initialState = {
    questions: [],
    // loading , error , ready , active , finished
    status: 'loading',
    currentIndex: 0,
    answer: null,
    points: 0,
    highScore: 0,
    secondsRemaining: null,
};

function reducer(state, action){

    switch(action.type){
        case 'dataReceived':
            return {...state, questions: action.payload, status : "ready"};
        case 'dataFailed':
            return {...state, status: "error"};
        case 'start':
            return {...state, status: "active", secondsRemaining: state.questions.length * SECONDS_PER_QUESTION
            };
        case 'newAnswer':
            const current_question = state.questions.at(state.currentIndex)
            return {
                ...state, 
                answer: action.payload, 
                points: (action.payload === current_question.correctOption) 
                ? state.points + current_question.points 
                : state.points,
                };
        case 'nextQuestion':  
            return {...state, currentIndex : state.currentIndex + 1, answer: null};   
        case 'finish':
            return {...state, status: "finish", highScore: (state.points > state.highScore) ? state.points : state.highScore};
        case 'reset':
            return {...initialState, questions: state.questions, status: "ready"};
        case 'tick':
            return {...state, secondsRemaining: state.secondsRemaining - 1, status: (state.secondsRemaining === 0) ? 'finish' : state.status};
        default:
            throw new Error("Action is unknown");
    }
}

export default function App (){

    const [{questions, status, currentIndex, points, answer, highScore, secondsRemaining}, dispatch] = useReducer(reducer, initialState);
    const numQuestions = questions.length;
    const maxPoints = questions.reduce((prev, curr) => prev + curr.points, 0);

    // const numQuestions = status === 'ready' || status === 'active' ? questions.length : 0;
    // const maxPoints = status === 'ready' || status === 'active' ? questions.reduce((prev, curr) => prev + curr.points, 0) : 0;


    //will not use API call because of json.server api call not works on hosted web page
    // useEffect( function () {
    //     fetch('http://localhost:9000/questions')
    //     .then((res) => res.json())
    //     .then(data => dispatch({type : 'dataReceived', payload : data}))
    //     .catch((err) => dispatch({type: 'dataFailed'}));
    // }, []);

    useEffect(() => {
        dispatch({ type: 'dataReceived', payload: data.questions });
    }, []);

    return(
        <div className="app">
            <Header/>
            <Main>
            {status === 'loading' && <Loader/>}
            {status === 'error' && <Error/>}
            {status === 'ready' && 
                <StartScreen numQuestions={numQuestions} dispatch={dispatch}/>}
            {status === 'active' && 
            <>
                <Progress numQuestions={numQuestions} currentIndex={currentIndex} points={points} maxPoints={maxPoints} answer={answer}/>
                <Questions question={questions[currentIndex]} dispatch={dispatch} answer={answer}/>
                <Footer>
                    <Timer dispatch={dispatch} secondsRemaining={secondsRemaining}/>
                    <NextButton dispatch={dispatch} answer={answer} currentIndex={currentIndex} numQuestions={numQuestions}/>
                </Footer>
            </>}
             {status === 'finish' && 
                <FinishQuiz points={points} maxPoints={points} highScore={highScore} dispatch={dispatch}/>}
           </Main>
        </div>
    );
}