import React from 'react'

export default function Options({question, dispatch, answer}) {

  const answeredQuestion = answer !== null;

  return (
    <div className='options'> 
    {question.options.map((option, index) => 
      <button 
        key={index} 
        disabled={answer!==null}
        className=
        {`btn btn-option ${index === answer ? "answer" : ""} 
        ${(answeredQuestion) ? 
          (index === question.correctOption) ?
           'correct' : 'wrong' 
          : ""}`}
        onClick={() => dispatch({type : 'newAnswer' , payload : index})}>{option}</button>)}
    </div>
  );
}
