import React from 'react'

export default function Progress({numQuestions, currentIndex, points, maxPoints, answer}) {
  return (
    <header className='progress'>
        <progress max={numQuestions} value={currentIndex + Number(answer !== null)}/>
        <p><strong>Question {currentIndex + 1}</strong>/<strong>{numQuestions}</strong></p>
        <p><strong>{points}</strong>/<strong>{maxPoints}  points</strong></p>  
    </header>
  );
}
 